<template>
  <div id="advertise-promotion">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>广告管理</el-breadcrumb-item>
      <el-breadcrumb-item>图文视频推广</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <span class="label">广告位置:</span>
            <el-select
              v-model="filtrate.position"
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option label="首页" value="首页"> </el-option>
              <el-option label="车主生活" value="车主生活"> </el-option>
            </el-select>
          </li>
          <li>
            <el-button type="primary" size="medium" @click="getList(1)"
              >查询</el-button
            >
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              class="create_btn"
              type="primary"
              @click="$refs.actionDialog.show = true"
              v-if="buttonList.includes('advertise-promotion-add')"
              ><i class="iconfont icon-jiahao"></i> 新建</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="mainTitle" label="主标题" align="center">
        </el-table-column>
        <el-table-column prop="subtitle" label="副标题" align="center">
        </el-table-column>
        <el-table-column label="封面" align="center">
          <template slot-scope="scope">
            <el-image
              style="width: 50px; height: 50px"
              :src="scope.row.cover.fileUrl"
              :preview-src-list="[scope.row.cover.fileUrl]"
              fit="contain"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          label="视频"
          align="center"
          v-if="filtrate.position === '首页'"
        >
          <template slot-scope="scope">
            <el-link
              v-if="scope.row?.video?.fileUrl"
              type="primary"
              :href="scope.row.video.fileUrl"
              target="_blank"
              >查看</el-link
            >
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="提交人" align="center">
        </el-table-column>
        <el-table-column prop="createAt" label="创建时间" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="promotion_edit(scope.row)"
              v-if="buttonList.includes('advertise-promotion-edit')"
              >编辑</el-button
            >
            <el-button
              v-if="buttonList.includes('advertise-promotion-delete')"
              type="text"
              @click="promotion_delete(scope.row.promotionId)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <actionDialog ref="actionDialog" @confirm="getList" />
  </div>
</template>
<script>
export default {
  components: {
    actionDialog: () => import("./actionDialog.vue"),
  },
  data() {
    return {
      filtrate: {
        keyword: "",
        position: "首页",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    reset() {
      this.filtrate.keyword = "";
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/ad/promotion/list", {
          pageNum: page,
          pageSize: this.table.pageSize,
          ...this.filtrate,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.createAt = this.$moment(item.createAt).format(
              "yyyy-MM-DD HH:mm:ss"
            );
          }
          this.table.tableData = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
    promotion_edit(row) {
      this.$refs.actionDialog.promotionId = row.promotionId;
      for (let key in this.$refs.actionDialog.form) {
        this.$refs.actionDialog.form[key] = row[key];
      }
      this.$refs.actionDialog.imgPreviewUrl = row.cover.fileUrl;
      this.$refs.actionDialog.videoPreviewUrl = row.video?.fileUrl;
      this.$refs.actionDialog.show = true;
    },
    promotion_delete(promotionId) {
      this.$confirm("此操作将永久删除该图文视频推广, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await this.$http.delete(
          `/ad/promotion/delete/${promotionId}`
        );
        if (res.code === 0) {
          this.$message.success("删除成功");
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss">
#advertise-promotion {
}
</style>
